import { useState, useEffect } from "react";
import WidgetForm from "../../Components/WidgetForm";
import { useAccount } from "wagmi";
export default function MainPage({ fetchChains }) {
  const [showWallet, setShowWallet] = useState(false);
  const { isConnected } = useAccount();
  function handleShowWallet(val) {
    setShowWallet(!showWallet);
  }

  // useEffect(() => {
  //   if (
  //     !isConnected &&
  //     typeof window?.ethereum?.overrideIsMetamask == "undefined" &&
  //     window?.ethereum?.isCoinbaseWallet
  //   ) {
  //     window.location.reload();
  //   }
  // }, [isConnected]);
  return (
    <div>
      <WidgetForm
        handleShowWallet={handleShowWallet}
        fetchChains={fetchChains}
        showWallet={showWallet}
      />
    </div>
  );
}
