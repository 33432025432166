import React, { useState, useEffect } from "react";
import TransactionTab from "./TransactionTab";
import TransactionList from "./TransactionList";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import controllers from "../../Actions/Controllers";
import useStore from "../../zustand/store";
import { useQueryClient } from "@tanstack/react-query";
import useWalletDetails from "../../hooks/useWalletDetails";
import useRegisterError from "../../hooks/useRegisterError";
function TransactionData() {
  const [value, setValue] = useState("all");
  function handleValue(val) {
    setValue(val);
  }
  const queryClient = useQueryClient();
  const fromChain = useStore((state) => state.fromChain);
  const { getWallet } = useWalletDetails({ chainData: fromChain });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const fromAddress = getWallet(fromChain)?.address || "";
  const { registerError } = useRegisterError();
  const historyData = useInfiniteQuery({
    queryKey: ["history", fromAddress, value, startDate, endDate],
    queryFn: async ({ pageParam = 1 }) => {
      let req = await controllers.fetchHistory(
        fromAddress,
        value === "all" || value == "date" ? "" : value,
        pageParam,
        startDate,
        endDate
      );
      return await req.json();
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      if (allPages.length < allPages[0]?.pageCount) {
        return allPages.length + 1;
      } else {
        return null;
      }
    },
    getPreviousPageParam: (lastPage, allPages) => {
      if (allPages.length < allPages[0]?.pageCount) {
        return allPages.length - 1;
      } else {
        return false;
      }
    },
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // if (data.status == "error") {
      //   registerError({ module: "/history", error: data.error });
      // }
    },
    onError: (err) => {
      // let error = err.details || err.message || "";
      // registerError({ module: "/history", error });
    },
  });
  function handleDate(start, end) {
    setStartDate(start ? new Date(start).getTime() : "");
    setEndDate(end ? new Date(end).getTime() : "");
  }
  useEffect(() => {
    queryClient.setQueryData(["history"], (existingData) => {
      return existingData
        ? {
            pageParams: [existingData.pageParams[0]],
            pages: [existingData.pages[0]],
          }
        : undefined;
    });
  }, [value]);

  return (
    <div className="bw-mt-4">
      <TransactionTab
        value={value}
        handleValue={handleValue}
        handleDate={handleDate}
      />
      <TransactionList historyData={historyData} />
    </div>
  );
}

export default TransactionData;
