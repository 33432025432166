import { useQuery } from "@tanstack/react-query";
import TxnItem from "./TxnItem";
import controllers from "../../Actions/Controllers";
import { keyBy } from "lodash";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import spinner from "../../lottie/spinner.json";
export default function TransactionList({ historyData }) {
  const [chainData, setChainData] = useState({});
  const chains = useQuery({
    queryKey: ["chains"],
    queryFn: async () => {
      let res = await controllers.fetchChain();
      return await res.json();
    },

    refetchOnWindowFocus: false,
  });
  console.log(chains.data, "chains.data");
  useEffect(() => {
    if (chains?.data?.data) {
      setChainData({ ...keyBy(chains.data.data, "chainId") });
    }
  }, [chains.data]);
  // console.log(historyData.data?.pages, "histroydata");
  return (
    <div className="bw-w-full bw-h-[400px] bw-overflow-y-auto">
      {!historyData.isLoading ? (
        historyData.data?.pages?.map((data, i, arr) => {
          return data?.data?.map((item, idx, array) => (
            <TxnItem
              item={item}
              key={idx}
              chainData={chainData}
              historyData={historyData}
              array={array}
              i={idx}
            />
          ));
        })
      ) : (
        <div className="bw-w-[36px] bw-h-[36px] bw-w-full bw-mt-6 bw-flex bw-justify-center">
          <Lottie animationData={spinner} loop={true} />
        </div>
      )}
    </div>
  );
}
