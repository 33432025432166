import truncate from "../../../utils/truncate";
import images from "../../../images";
import React, { useState, useEffect } from "react";
import useStore from "../../../zustand/store";
import Gas from "../../Svg/gas";
import ClockIcon from "../../Svg/clockIcon";
import Step from "../../Svg/step";
import Routes from "../../Tooltip/Routes";
import Maxop from "../../Svg/maxop";
import LowgasSvg from "../../Svg/lowgas";
import FastestSvg from "../../Svg/fastest";
import fixChar from "../../../utils/fixChar";
function GasField({ gasImg, item, fromChain }) {
  const [gas, setGas] = useState({});
  useEffect(() => {
    let tempGasObj = { amountInUSD: 0 };
    item?.fee?.forEach((item) => {
      if (item.source == "FROM_SOURCE_WALLET") {
        tempGasObj.amountInUSD += Number(item.amountInUSD);
      }
    });
    setGas(tempGasObj);
  }, [item]);
  return (
    <div className="bw-flex bw-text-sm bw-items-center bw-gap-x-1">
      <Gas fill={"var(--tw-text-secondary)"} />
      <p>${truncate(gas?.amountInUSD || 0, 4) || 0}</p>
    </div>
  );
}
export default function Fastest({
  routes,
  handleShowAllRoutes,
  handleRoutesData,
  convertVal,
  fromChain,
  sort,
  toChain,
}) {
  const { gasnew, clockicon, step, maxop, fast, lowgas, defaultCoin } = images;
  const [recommendedGas, setRecommendedGas] = useState({});
  const [recommendedOp, setRecommendedOp] = useState({});
  const configuration = useStore((state) => state.configuration);
  function handleOpCalc(item, arr, i) {
    let opVal = truncate((item.estimatedTimeInSeconds || 60) / 60, 2) || 0;

    let firstOpVal =
      sort == "asc"
        ? truncate(
            (arr[arr.length - 1].estimatedTimeInSeconds || 60) / 60,
            2
          ) || 0
        : truncate((arr[0].estimatedTimeInSeconds || 60) / 60, 2) || 0;

    let lastOpVal =
      sort == "asc"
        ? truncate((arr[0].estimatedTimeInSeconds || 60) / 60, 2) || 0
        : truncate(
            (arr[arr.length - 1].estimatedTimeInSeconds || 60) / 60,
            2
          ) || 0;

    if ((i == 0 && sort !== "asc") || (i == arr.length - 1 && sort == "asc")) {
      return {
        val: (
          <p className="bw-text-[10px] bw-text-text-secondary bw-font-normal">
            <span className="bw-text-text-greentext">
              {`+${truncate(lastOpVal - firstOpVal || 0, 2)} Mins`}
            </span>{" "}
            Faster
          </p>
        ),
        isMax: true,
      };
    } else if (
      (i == arr.length - 1 && sort !== "asc") ||
      (i === 0 && sort == "asc")
    ) {
      return {
        val: (
          <p className="bw-text-[10px] bw-text-text-secondary bw-font-normal">
            <span
              className={`${
                firstOpVal - lastOpVal === 0
                  ? "bw-text-text-greentext"
                  : "bw-bg-background-container"
              }`}
            >
              {`${truncate(lastOpVal - firstOpVal, 2)} Mins`}
            </span>{" "}
            {firstOpVal - lastOpVal === 0 ? "Faster" : "Slower"}
          </p>
        ),
        isMax: firstOpVal - lastOpVal === 0 ? true : false,
      };
    } else {
      return {
        val: (
          <p className="bw-text-[10px] bw-text-text-secondary bw-font-normal">
            <span
              className={`${
                firstOpVal - opVal === 0
                  ? "bw-text-text-greentext"
                  : "bw-bg-background-container"
              }`}
            >
              {`${truncate(opVal - firstOpVal, 2)} Mins`}
            </span>{" "}
            {firstOpVal - opVal === 0 ? "Faster" : "Slower"}
          </p>
        ),
        isMax: firstOpVal - opVal === 0 ? true : false,
      };
    }
  }
  return (
    <div>
      <div className="bw-h-[410px] bw-pt-2 bw-overflow-y-auto">
        {routes.data?.data?.quotes
          ?.sort((a, b) => {
            let opVal1 =
              truncate((a.estimatedTimeInSeconds || 60) / 60, 2) || 0;

            let opVal2 =
              truncate((b.estimatedTimeInSeconds || 60) / 60, 2) || 0;
            if (sort === "asc") {
              return opVal2 - opVal1;
            } else {
              return opVal1 - opVal2;
            }
          })
          .map((item, i, arr) => {
            const calculatedOpVal = handleOpCalc(item, arr, i);
            let outputVal = truncate(
              Number(
                item?.minOutputAmountDisplay || item?.outputAmountDisplay || 0
              ),
              10
            );
            return (
              <div
                key={i}
                onClick={() => {
                  handleRoutesData(item);
                  handleShowAllRoutes();
                }}
                className="bw-mb-4"
              >
                <div
                  style={{
                    boxShadow:
                      (configuration &&
                        configuration.customTheme &&
                        configuration.customTheme?.shadow?.boxShadow) ||
                      "1px 1px 7px rgba(0, 0, 0, 0.15)",
                  }}
                  className={` bw-w-[93%]  bw-mt-3 bw-cursor-pointer bw-ml-auto bw-mr-2 bw-flex bw-mb-8 bw-items-center bw-px-2 bw-relative bw-rounded-lg bw-pt-3 bw-pb-3 bw-border bw-border-border-primary`}
                >
                  <div className="bw-absolute bw-flex bw-items-center bw-gap-x-1 bw-bottom-[-10%] bw-self-center bw-right-[47%]">
                    {item.tags?.includes("FAST") ? (
                      <Routes name={"Fastest"}>
                        <div className="bw-w-[20px] bw-h-[20px]">
                          <FastestSvg fill={"var(--tw-background-container)"} />
                        </div>
                      </Routes>
                    ) : (
                      <></>
                    )}
                    {item.tags?.includes("CHEAP") ? (
                      <Routes name={"Lowest Gas"}>
                        <div className="bw-w-[20px] bw-h-[20px]">
                          <LowgasSvg fill={"var(--tw-background-container)"} />
                        </div>
                      </Routes>
                    ) : (
                      <></>
                    )}
                    {item.tags?.includes("BEST_OUTPUT") ? (
                      <Routes name={"Max Output"}>
                        <div className="bw-w-[20px] bw-h-[20px]">
                          <Maxop fill={"var(--tw-background-container)"} />
                        </div>
                      </Routes>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="bw-w-[35px] bw-h-[35px] bw-absolute bw-z-50 bw-left-[-5%] bw-top-[-16%]">
                    <img
                      src={item?.providerDetails?.logoUrl}
                      className="bw-rounded-lg "
                      width={35}
                      height={35}
                    />
                  </div>
                  <div className="bw-absolute bw-text-[10px] bw-text-text-primary bw-font-normal bw-left-5 bw-top-1">
                    <span className={`${calculatedOpVal.color}`}>
                      {calculatedOpVal?.val}
                    </span>{" "}
                  </div>
                  <div className="bw-flex bw-w-full bw-mt-4  bw-justify-between bw-items-center">
                    <div>
                      <p className="bw-text-xs bw-font-semibold bw-text-text-primary">
                        {item?.providerDetails?.name || ""}
                      </p>

                      <p className="bw-text-xs bw-font-normal bw-my-1 bw-text-text-secondary">
                        via{" "}
                        {item?.protocolsUsed?.length > 2
                          ? item?.protocolsUsed
                              .slice(0, 2)
                              .map((item, i, arr) => {
                                return i == arr.length - 1
                                  ? item
                                  : `${item + " >> "}`;
                              }) + `+${item?.protocolsUsed?.length - 2}`
                          : item?.protocolsUsed.map((item, i, arr) => {
                              return i == arr.length - 1
                                ? item
                                : `${item + " >> "}`;
                            })}{" "}
                      </p>
                      <div className="bw-flex bw-items-center bw-gap-x-1">
                        <div className="bw-flex bw-items-center bw-gap-x-1">
                          <Step fill={"var(--tw-text-secondary)"} />
                          <p className="bw-text-xs bw-font-normal bw-text-text-secondary">
                            {item.steps.length} Step
                          </p>
                        </div>
                        <div className="bw-text-xs bw-font-normal bw-text-text-secondary">
                          {" "}
                          Slippage {item.slippage}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bw-flex bw-flex-col bw-justify-between bw-w-full bw-items-end">
                    <div className="bw-flex bw-items-center bw-gap-x-2">
                      <div className="  bw-bg-background-coin  bw-relative bw-rounded-[50%] bw-w-[21px] bw-h-[21px] bw-border bw-border-border-primary">
                        {item.to?.image ? (
                          <img
                            src={item.to.image}
                            width={20}
                            height={20}
                            alt="img"
                            className="bw-rounded-[50%]"
                          />
                        ) : (
                          <img
                            src={defaultCoin}
                            width={20}
                            height={20}
                            alt="img"
                            className="bw-rounded-[50%]"
                          />
                        )}
                        <div className="bw-bg-background-coin bw-absolute bw-bottom-[-30%] bw-right-[-15%] bw-rounded-[50%] bw-w-[12px] bw-h-[12px] bw-border bw-border-border-primary">
                          {" "}
                          {toChain?.image ? (
                            <img
                              src={toChain.image}
                              width={11}
                              height={11}
                              className="bw-rounded-[50%]"
                              alt="img"
                            />
                          ) : (
                            <img
                              src={defaultCoin}
                              width={11}
                              height={11}
                              className="bw-rounded-[50%]"
                              alt="img"
                            />
                          )}
                        </div>
                      </div>
                      <p className="bw-text-lg  bw-break-words bw-font-medium bw-text-text-primary">
                        {fixChar(outputVal, 10)}{" "}
                      </p>
                    </div>
                    <div className="bw-flex bw-text-xs bw-font-normal bw-mb-2  bw-items-center bw-gap-x-1">
                      <p className="bw-text-text-secondary">
                        $
                        {truncate(
                          Number(
                            item.minOutputAmountDisplay ||
                              item.outputAmountDisplay
                          ) * convertVal,
                          4
                        )}
                      </p>
                    </div>
                    <div className="bw-text-sm  bw-flex bw-items-center bw-gap-x-2 bw-font-medium bw-text-text-secondary">
                      <div className="bw-flex bw-text-sm bw-items-center bw-gap-x-1">
                        <ClockIcon fill={"var(--tw-text-secondary)"} />
                        <p>
                          {item.estimatedTimeInSeconds >= 90
                            ? `${
                                Math.round(
                                  truncate(
                                    (item.estimatedTimeInSeconds || 60) / 60,
                                    2
                                  )
                                ) || 0
                              }min`
                            : `${item?.estimatedTimeInSeconds || 60} secs`}
                        </p>
                      </div>
                      <GasField
                        item={item}
                        gasImg={gasnew}
                        fromChain={fromChain}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
