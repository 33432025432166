import SwapIcon from "../Svg/swapIcon";
import useStore from "../../zustand/store";
export default function SwapAsset({ handleSwap }) {
  const fromCoin = useStore((state) => state.fromCoin);
  const toCoin = useStore((state) => state.toCoin);
  let isCoins = fromCoin?.coin?.length && toCoin.coin?.length;
  return (
    <div
      onClick={handleSwap}
      className={`${
        !isCoins ? "bw-pointer-events-none" : ""
      } bw-absolute hover:bw-scale-[0.95] bw-border bw-border-border-primary bw-rounded-[50%] bw-cursor-pointer bw-bg-background-container bw-top-[33%] bw-right-[45%] `}
    >
      <SwapIcon
        fill1={"var(--tw-background-card)"}
        fill2={isCoins ? "var(--tw-text-primary)" : "var(--tw-text-secondary)"}
      />
    </div>
  );
}
