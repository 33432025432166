import React, { useState, useEffect } from "react";
import { useConnect, useAccount, useSwitchAccount } from "wagmi";
import SolanaWallet from "../SolanaWallet";
import useStore from "../../zustand/store";
import BackButton from "../Svg/backButton";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import InstalledIcon from "../InstalledIcon";
import CloseButton from "../Svg/closeButton";
import TronWallet from "../TronWallet";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useWalletDetails from "../../hooks/useWalletDetails";
import ConnectorLoader from "./connectorLoader";
import WalletTab from "./walletTab";
import CustomWallet from "./customWallet";
import styles from "./SelectWallet.module.css";
import CosmosWallet from "../CosmosWallet";
import { useAccount as useCosmosAccount } from "graz";
import EvmWallet from "../EvmWallet";
import config from "../../service/wagmiConfig";
import { useWeb3Modal } from "@web3modal/wagmi/react";
export default function SelectWallet({
  handleShowWallet,
  connectWalletField,
  portfolio,
  handleRecentSelectedWallet,
}) {
  const { connectAsync, data, isSuccess } = useConnect({ config });
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const { primaryWallet } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const [selectedWallet, setSelectedWallet] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [selectedConnector, setSelectedConnector] = useState();
  const [isSolConnecting, setIsSolanaConnecting] = useState(false);
  const [isTronConnecting, setIsTronConnecting] = useState(false);
  const [isCosmosonnecting, setIsCosmosConnecting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { connected, address } = useWallet();
  const { handleEVMDisable } = useWalletDetails({ fromChain, toChain });
  const [selectedWalletTab, setSelectedWalletTab] = useState("All");
  const { data: accounts, isConnected: isCosmosConnected } = useCosmosAccount();
  const { switchAccountAsync } = useSwitchAccount();
  const { open } = useWeb3Modal();
  async function handleConnect(connector, isWalletConnected) {
    setErrorMsg("");
    let action = isWalletConnected ? switchAccountAsync : connectAsync;
    try {
      if (connector.id !== "walletConnect") {
        let result = await action({ connector });
        if (isWalletConnected) {
          handleShowWallet();
        }
        setErrorMsg((prev) => {
          if (prev?.length && result) {
            handleShowWallet();
          }
          return "";
        });
      } else {
        open({ view: "Connect" });
        handleShowWallet();
      }
    } catch (err) {
      console.log(err, "error");
      setErrorMsg(err.details || err.message || "");
    }
  }
  useEffect(() => {
    let timeout;
    if (data && isSuccess) {
      timeout = setTimeout(() => {
        handleShowWallet();
      }, 1000);
      !portfolio && handleRecentSelectedWallet("evm");
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess, data]);
  function handleSolConnecting(val, wallet) {
    setErrorMsg("");
    if (wallet) {
      setSelectedWallet(wallet);
    }
    setIsSolanaConnecting(val);
    setShowLoading(false);
  }
  function handleOtherWalletConnecting() {
    setShowLoading(true);
    setIsSolanaConnecting(false);
    setIsTronConnecting(false);
  }
  function handleTronConnection(val, wallet) {
    setErrorMsg("");
    if (wallet) setSelectedWallet(wallet);
    setIsTronConnecting(val);
    setShowLoading(false);
  }
  function handleCosmosConnection(val, wallet) {
    setErrorMsg("");
    if (wallet) setSelectedWallet(wallet);
    setIsCosmosConnecting(val);
    setShowLoading(false);
  }
  useEffect(() => {
    let timeout;
    if (isFullyConnected && primaryWallet !== null && isSolConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleSolConnecting(false);
      }, 1000);
      !portfolio && handleRecentSelectedWallet("sol");
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFullyConnected, primaryWallet, isSolConnecting]);

  useEffect(() => {
    let timeout;
    if (connected && address && isTronConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleTronConnection(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [connected, address, isTronConnecting]);
  useEffect(() => {
    let timeout;
    if (isCosmosConnected && accounts?.bech32Address && isCosmosonnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleCosmosConnection(false);
      }, 1000);
      !portfolio && handleRecentSelectedWallet("cosmos");
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCosmosConnected, accounts?.bech32Address, isCosmosonnecting]);
  function handleWalletTab(wallet) {
    setSelectedWalletTab(wallet);
  }
  return showLoading ? (
    <div className="">
      <div className="bw-flex bw-relative bw-justify-center bw-mb-2">
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-left-0 bw-top-[25%]"
        >
          <BackButton fill={"var(--tw-text-secondary)"} />
        </button>
        <div className="bw-text-lg bw-font-medium bw-text-text-secondary">
          Connect Wallet
        </div>
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-right-0 bw-top-[25%]"
        >
          <CloseButton fill={"var(--tw-text-secondary)"} />
        </button>
      </div>
      <WalletTab
        selectedWalletTab={selectedWalletTab}
        handleWalletTab={handleWalletTab}
      />
      <div
        className={`${
          portfolio ? "bw-h-[630px]" : "bw-h-[400px]"
        } bw-overflow-y-auto  bw-mt-4  `}
      >
        <div
          className={` bw-mx-auto bw-px-2  bw-w-full bw-flex bw-justify-between bw-flex-wrap `}
        >
          <EvmWallet
            selectedWalletTab={selectedWalletTab}
            styles={styles}
            connectWalletField={connectWalletField}
            handleShowWallet={handleShowWallet}
            setSelectedConnector={setSelectedConnector}
            setSelectedWallet={setSelectedWallet}
            handleConnect={handleConnect}
            setShowLoading={setShowLoading}
          />

          {selectedWalletTab == "All" || selectedWalletTab == "SVM" ? (
            <SolanaWallet
              handleShowWallet={handleShowWallet}
              fromChain={fromChain}
              toChain={toChain}
              connectWalletField={connectWalletField}
              handleSolConnecting={handleSolConnecting}
              portfolio={portfolio}
              selectedWalletTab={selectedWalletTab}
            />
          ) : (
            <></>
          )}
          {selectedWalletTab == "All" || selectedWalletTab == "Cosmos" ? (
            <CosmosWallet
              selectedWalletTab={selectedWalletTab}
              handleCosmosConnection={handleCosmosConnection}
              setErrorMsg={setErrorMsg}
              connectWalletField={connectWalletField}
              handleShowWallet={handleShowWallet}
            />
          ) : (
            <></>
          )}
          {selectedWalletTab == "All" || selectedWalletTab == "Other" ? (
            <TronWallet
              handleTronConnection={handleTronConnection}
              connectWalletField={connectWalletField}
              fromChain={fromChain}
              toChain={toChain}
              selectedWalletTab={selectedWalletTab}
              handleShowWallet={handleShowWallet}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {connectWalletField == "to" ? (
        <CustomWallet
          portfolio={portfolio}
          handleShowWallet={handleShowWallet}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <ConnectorLoader
      selectedWallet={selectedWallet}
      selectedConnector={selectedConnector}
      handleConnect={handleConnect}
      handleOtherWalletConnecting={handleOtherWalletConnecting}
      isSolConnecting={isSolConnecting}
      isTronConnecting={isTronConnecting}
      isCosmosonnecting={isCosmosonnecting}
      isSuccess={isSuccess}
      data={data}
      evmError={errorMsg}
    />
  );
}
