import React, { useEffect, useState } from "react";
import {
  useStargateSigningClient,
  useCosmWasmSigningClient,
  useAccount,
} from "graz";
import { Registry } from "@cosmjs/proto-signing";
import { defaultRegistryTypes } from "@cosmjs/stargate";
import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
import useStore from "../zustand/store";
import executeRouteCosmos from "../utils/cosmosExecution";
export default function useCosmosTxn() {
  const [cosmosError, setCosmosError] = useState(false);
  const [cosmosData, setCosmosData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const cosmosChainIds = useStore((state) => state.cosmosChainIds);

  const { data: signingClient } = useCosmWasmSigningClient({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  const fromChain = useStore((state) => state.fromChain);
  const { data: stargateClient, isLoading } = useStargateSigningClient({
    chainId: [fromChain.chainId],
    multiChain: true,
  });
  const persist = useStore((state) => state.persist);
  const { data } = useAccount({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  console.log(stargateClient, data, "stargateclient");
  console.log(MsgTransfer, "messagetrans");
  function resetCosmosData() {
    setCosmosError(false);
    setErrorMessage("");
    setCosmosData(null);
  }
  const getCustomRegistry = () => {
    const registry = new Registry(defaultRegistryTypes);
    registry.register("/ibc.applications.transfer.v1.MsgTransfer", MsgTransfer);
    return registry;
  };

  async function handleCosmosTransaction(txndata) {
    console.log(stargateClient, signingClient, txndata, "condition check");
    if (stargateClient && txndata) {
      const parsedOuter = JSON.parse(txndata.txnData.txnCosmos.data);
      const offlineSigner = window.keplr.getOfflineSigner("cosmoshub-4");
      // const client = await SigningStargateClient.connectWithSigner(
      //   rpcEndpoint,
      //   offlineSigner,
      //   {
      //     registry,
      //   }
      // );

      // const offlineSigner = await wallet.getOfflineSigner();

      // Create custom registry
      const registry = getCustomRegistry("");
      try {
        setCosmosError(false);
        setErrorMessage("");
        let txn = await executeRouteCosmos(
          stargateClient?.[fromChain.chainId],
          data?.[fromChain.chainId]?.bech32Address,
          {
            transactionRequest: { ...txndata.txnData.txnCosmos },
          },
          true,
          persist?.route
        );
        setCosmosData({ signature: txn.transactionHash });
        console.log(txn, "squidtxn");
      } catch (err) {
        console.log(err, "cosmerr");
        setCosmosError(true);
        setErrorMessage(err);
      }
      // Create SigningStargateClient with custom registry
      // const rpcEndpoint = "https://cosmos-rpc.publicnode.com:443"; // Replace with your RPC endpoint
      // const client = await SigningStargateClient.connectWithSigner(
      //   rpcEndpoint,
      //   offlineSigner,
      //   { registry }
      // );
      // console.log(parsedOuter, "parseout");
      // const timeoutTimestamp = Long.fromValue(
      //   parsedOuter.msg.timeoutTimestamp
      // ).toString();
      // let txVal = { ...parsedOuter.msg, timeoutTimestamp };
      // console.log(txVal, "txnval");
      // let msg = {
      //   typeUrl: parsedOuter.msgTypeUrl,
      //   value: MsgTransfer.fromPartial(txVal),
      // };
      // console.log(msg, "msg");
      // try {
      //   let txn = await stargateClient.signAndBroadcast(
      //     data?.bech32Address,
      //     [msg],
      //     {
      //       gas: "10000",
      //       amount: [
      //         {
      //           denom: "uatom",
      //           amount: "127745",
      //         },
      //       ],
      //     },
      //     ""
      //   );
      //   setCosmosData({ signature: txn.transactionHash });
      // } catch (err) {
      //   setCosmosError(true);
      //   setErrorMessage(err);
      // }
    }
  }
  return {
    handleCosmosTransaction,
    cosmosData,
    cosmosError,
    cosmosErrorMessgae: errorMessage,
    resetCosmosData,
  };
}
