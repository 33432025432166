import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import images from "../../images";
import useStore from "../../zustand/store";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useAccount } from "graz";
const useStyles = makeStyles(() => ({
  circle: {
    stroke: "url(#linearColors)",
  },
  MuiCircularProgress: { circle: { color: "green" } },
}));
function CircularProgressWithLabel(props) {
  const classes = useStyles();
  const {
    injectedicon,
    metamaskicon,
    walletconnecticon,
    coinbaseicon,
    phantom,
    solflare,
    slope,
    magiceden,
    backpacksol,
    tronLink,
    keplr,
  } = images;
  const walletIcons = {
    Injected: injectedicon,
    metaMask: metamaskicon,
    coinbaseWallet: coinbaseicon,
    WalletConnect: walletconnecticon,
    solflare,
    phantom,
    slope,
    backpack: backpacksol,
    keplr,
    "magic eden (solana)": magiceden,
    TronLink: tronLink,
  };
  const configuration = useStore((state) => state.configuration);
  return (
    <>
      <svg className="bw-absolute">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop
            offset="1%"
            stopColor={
              configuration?.gradientStyle?.spinnerColor
                ? configuration?.gradientStyle?.spinnerColor
                : "#A45EFF"
            }
          />
          <stop
            offset="90%"
            stopColor={
              configuration?.gradientStyle?.spinnerColor
                ? configuration?.gradientStyle?.stopColor
                : "#2CFFE4"
            }
          />
        </linearGradient>
      </svg>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={250}
          thickness={1}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          size={250}
          thickness={1}
          value={props.value}
          sx={{
            "svg circle": { stroke: "url(#linearColors)" },
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            className="bw-flex bw-flex-col bw-justify-center bw-items-center bw-text-2xl bw-font-medium bw-text-text-primary"
            component="div"
            color="text.secondary"
          >
            <img
              src={
                walletIcons[props?.selectedWallet] ||
                props.selectedConnector?.icon ||
                ""
              }
              width={90}
              height={83}
              alt="img"
            />
            <p className="bw-text-2xl bw-px-3 bw-mt-1 bw-text-center bw-font-medium bw-text-text-primary">
              {props.selectedWallet}
            </p>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function ConnectWalletProgress({
  selectedWallet,
  isSuccess,
  data,
  isSolConnecting,
  isCosmosonnecting,
  isTronConnecting,
  selectedConnector,
}) {
  const { primaryWallet } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const [progress, setProgress] = React.useState(25);
  const { address, connected } = useWallet();
  const { data: accounts } = useAccount();
  console.log(accounts, "Accounts");
  React.useEffect(() => {
    ((isSuccess && data) ||
      (primaryWallet !== null && isFullyConnected && isSolConnecting) ||
      (address && connected && isTronConnecting) ||
      (accounts?.bech32Address && isCosmosonnecting)) &&
      setProgress(100);
  }, [
    isSuccess,
    data,
    isSolConnecting,
    primaryWallet,
    isFullyConnected,
    isTronConnecting,
    isCosmosonnecting,
    accounts?.bech32Address,
    address,
    connected,
  ]);
  return (
    <CircularProgressWithLabel
      value={progress}
      selectedWallet={selectedWallet}
      selectedConnector={selectedConnector}
    />
  );
}
