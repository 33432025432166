import PortfolioHeader from "../../Components/PortfolioComp/PortfolioHeader";
import PortfolioDisconnect from "../../Components/PortfolioComp/PortfolioDisconnect";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { useAccount } from "wagmi";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import PortfolioConnected from "../../Components/PortfolioComp/PortfolioConnected";
import { useEffect, useState } from "react";
import { useAccount as useAccountGraz } from "graz";
export default function Portfolio() {
  const { isConnected } = useAccount();
  const { primaryWallet } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const { connected, connecting } = useWallet();
  const [connectedWallet, setConnectedWallet] = useState(true);
  const { data, isConnected: isConnectedCosmos } = useAccountGraz();
  function handleConnectedWallet(val) {
    setConnectedWallet(val);
  }
  useEffect(() => {
    if (
      isConnected ||
      (primaryWallet !== null && isFullyConnected) ||
      connected ||
      isConnectedCosmos
    ) {
      handleConnectedWallet(true);
    } else {
      handleConnectedWallet(false);
    }
  }, [
    isConnected,
    primaryWallet,
    isConnectedCosmos,
    isFullyConnected,
    connected,
  ]);
  return (
    <div className="bw-min-h-[660px]">
      <PortfolioHeader />
      {connectedWallet ? (
        <PortfolioConnected />
      ) : (
        <PortfolioDisconnect handleConnectedWallet={handleConnectedWallet} />
      )}
    </div>
  );
}
