import config from "../../service/config";
import useStore from "../../zustand/store";
import PackageJson from "../../../package.json";
const controllers = {
  async fetchChain() {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(`${config.BACKEND}/chains`, { ...sessionHeader });
  },
  async fetchTokens(id, address) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(
      `${config.BACKEND}/tokens?chainId=${id}&search=${address}`,
      { ...sessionHeader }
    );
  },
  async fetchRoutes(
    recipient,
    fromChain,
    toChain,
    fromCoin,
    toCoin,
    value,
    slippage,
    toChainAddress
  ) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(
      `${config.BACKEND}/quotes?fromChainId=${fromChain.chainId}&toChainId=${toChain.chainId}&fromAssetAddress=${fromCoin.address}&toAssetAddress=${toCoin.address}&inputAmountDisplay=${value}&userWalletAddress=${recipient}&slippage=${slippage}&recipient=${toChainAddress}`,
      { ...sessionHeader }
    );
  },
  async fetchTxnBody(params) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(`${config.BACKEND + params}`, { ...sessionHeader });
  },
  async fetchBalance(fromWallet, id) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(
      `${config.BACKEND}/balances?walletAddress=${fromWallet}&chainId=${
        id || ""
      }`,
      { ...sessionHeader }
    );
  },
  async convertVal(from, to, native, fromKey) {
    return await fetch(
      `${config.COINGECKO}/api/v3/simple/price?ids=${
        (from || "") + "," + (to || "") + "," + (native || "")
      }&vs_currencies=${"usd" + "," + (fromKey || "")}`
    );
  },
  async fetchNextTx(routeid, stepid) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(
      `${config.BACKEND}/nextTx?routeId=${stepid}&stepId=${routeid}`,
      { ...sessionHeader }
    );
  },
  async fetchStatus(routeid, stepid, txnhash) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(`${config.BACKEND}/submitTxHash`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
      body: JSON.stringify({
        routeId: routeid,
        stepId: stepid,
        txnHash: txnhash,
      }),
    });
  },
  async fetchPortfolio(body) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(`${config.BACKEND}/portfolio`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
      body: JSON.stringify(body),
    });
  },
  async fetchHistory(walletaddress, status, page, startDate, endDate) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(
      `${config.BACKEND}/history?address=${walletaddress}&status=${status}&pageNo=${page}&pageSize=20&startDate=${startDate}&endDate=${endDate}`,
      { ...sessionHeader }
    );
  },
  async fetchPoints(address) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(
      `${config.BACKEND}/points?userWalletAddress=${address}`,
      { ...sessionHeader }
    );
  },
  async fetchTxnDetails(id) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(`${config.BACKEND}/txn-detail?routeId=${id}`, {
      ...sessionHeader,
    });
  },
  async registerError(body) {
    const sessionId = useStore.getState().userId;
    const integratorId = useStore.getState().integratorId;
    const sessionHeader = {
      headers: {
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
    };
    return await fetch(`${config.BACKEND}/register-error`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-Session-ID": sessionId,
        "X-Integrator-ID": integratorId,
        "x-widget-version": PackageJson.version,
      },
      body: JSON.stringify(body),
    });
  },
};

export default controllers;
