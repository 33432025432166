import truncate from "../../utils/truncate";
import useStore from "../../zustand/store";
import Copy from "../Svg/copy";
import ShareIcon from "../Svg/shareicon";
import TxnHash from "../Tooltip/TxnHash";
import SuccessSpinner from "../../lottie/successspinner.json";
import SuccessJsonNew from "../../lottie/sucessJsonNew.json";
import Lottie from "lottie-react";
import styles from "./Exchange.module.css";
export default function SuccessCard({ handleBack }) {
  const gradient = useStore((state) => state.gradient);
  const persist = useStore((state) => state.persist);
  const configuration = useStore((state) => state.configuration);
  const lastStepType = persist?.stepData.stepType;
  const txnHashObj = persist?.txnHashObj || [];
  const lastTxnHash = txnHashObj[persist?.stepData?.stepId] || {};
  const txnHashCurrent =
    lastStepType === "bridge"
      ? lastTxnHash?.destTxnHash || lastTxnHash?.srcTxnHash
      : lastTxnHash?.srcTxnHash || "";
  const txnHashHistory = persist?.historyData?.finalTxnHash;
  const txnHash = txnHashCurrent || txnHashHistory || "";
  const txnUrlCurrent =
    lastStepType === "bridge"
      ? lastTxnHash?.destTxnUrl || lastTxnHash?.srcTxnUrl
      : lastTxnHash?.srcTxnUrl || "";
  const txnUrlHistory = persist?.historyData?.finalTxnUrl;
  const txnUrl = txnUrlCurrent || txnUrlHistory || "";
  const toCoin = persist.toCoin || {};
  const route = persist.route || {};
  const toChain = persist.toChain || {};
  // console.log(lastStepType, txnHash, txnUrl, lastTxnHash, "txntype123");
  return (
    <div className="bw-absolute bw-bg-background-container  bw-border-t bw-pt-3 bw-border-border-primary bw-w-full bw-flex bw-flex-col bw-z-50 bw-items-center bw-justify-center bw-bottom-0">
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "1px 1px 7px rgba(0, 0, 0, 0.15)",
        }}
        className="bw-flex bw-items-center bw-rounded-xl bw-w-full bw-justify-between bw-bg-background-container bw-border bw-border-border-primary bw-p-2"
      >
        <div>
          <p
            style={{ ...configuration.gradientStyle, backgroundClip: "text" }}
            className={`bw-text-lg ${
              gradient
                ? gradient
                : `${
                    !configuration?.gradientStyle?.background
                      ? "bw-bg-gradient-to-l bw-from-[#2CFFE4]  bw-to-[#A45EFF]"
                      : ""
                  }`
            } bw-bg-clip-text bw-font-medium bw-text-transparent`}
          >
            Transaction Successful
          </p>
          <p className="bw-text-2xl bw-font-medium bw-text-text-primary">{`${truncate(
            route?.minOutputAmountDisplay || route.outputAmountDisplay || 0,
            6
          )} ${toCoin.symbol}`}</p>
          <p className="bw-text-sm bw-mb-2 bw-font-normal bw-text-text-secondary">{`Received on ${toChain.name} chain`}</p>
          <div className="bw-flex bw-items-center bw-gap-x-1">
            <p className="bw-text-sm bw-font-normal bw-text-text-secondary">{`Tx id: ${
              txnHash
                ? `${txnHash.substring(0, 5)}...${txnHash.substring(
                    txnHash.length - 5,
                    txnHash.length
                  )}`
                : ""
            }`}</p>
            <div className="bw-cursor-pointer">
              <TxnHash txnHash={txnHash}>
                <div>
                  <Copy fill={"var(--tw-text-secondary)"} />
                </div>
              </TxnHash>
            </div>
            <a href={txnUrl} target="_blank">
              <div className="bw-cursor-pointer">
                <ShareIcon fill={"var(--tw-text-secondary)"} />
              </div>
            </a>
          </div>
        </div>
        <div className=" bw-flex bw-justify-center bw-items-center bw-w-[105px] bw-h-[105px]">
          {/* <img
            src={successGif}
            width={100}
            height={100}
            alt="img"
            className="bw-object-fit-contain"
          /> */}

          <Lottie animationData={SuccessJsonNew} loop={true} />
        </div>
      </div>
      <button
        onClick={handleBack}
        style={{ ...configuration?.gradientStyle }}
        className={`bw-w-full ${styles.gradientbutton} bw-rounded-sm bw-h-[50px] bw-p-[3px] bw-mt-3 bw-border-0 bw-relative bw-z-0  disabled:bw-opacity-50  bw-text-2xl bw-font-bold bw-text-text-primary`}
      >
        <div
          onClick={handleBack}
          className="bw-rounded-sm bw-bg-background-container bw-flex bw-items-center bw-justify-center b-w-full bw-h-full"
        >
          Start Again
        </div>
      </button>
    </div>
  );
}
