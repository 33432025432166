import React, { useState } from "react";
import WalletIcon from "../Svg/walletIcon";
import InputTick from "../Svg/inputtick";
import useStore from "../../zustand/store";
import images from "../../images";
import styles from "./SelectWallet.module.css";
import DisconnectWallet from "../Svg/disconnectWallet";
function CustomWallet({ portfolio, handleShowWallet }) {
  const configuration = useStore((state) => state.configuration);
  const customWallet = useStore((state) => state.customWallet);
  const setCustomWallet = useStore((state) => state.setCustomWallet);
  const toDisconnect = useStore((state) => state.toDisconnect);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const isCustomWalletSet = useStore((state) => state.isCustomWalletSet);
  const setIsCustomWalletSet = useStore((state) => state.setIsCustomWalletSet);
  const { disconnecticon } = images;
  const [errorText, setErrorText] = useState("");
  function handleSubmit() {
    console.log("connect");
    if (customWallet?.length > 30) {
      handleShowWallet();
      setIsCustomWalletSet(true);
      setToDisconnect(false);
    } else {
      setErrorText("Please enter a valid address");
    }
  }
  console.log(toDisconnect, customWallet, "tod");
  return (
    <div
      className={`bw-sticky bw-border-t bw-pt-3 bw-border-border-primary ${
        portfolio ? "bw-w-[calc(100%]" : "bw-w-[calc(100%+20px)]"
      } bw-ml-[-10px] bw-bottom-0`}
    >
      <div className="bw-flex bw-mx-4 bw-items-center bw-mb-2 bw-gap-x-1">
        <WalletIcon fill={"var(--tw-text-secondary)"} />
        <p className="bw-text-sm bw-font-semibold bw-text-text-secondary">
          Send to a different wallet address
        </p>
      </div>

      <form
        className="bw-w-full bw-px-4 bw-flex bw-gap-x-2 bw-justify-between bw-items-center"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div
          className={`bw-rounded-md  ${
            isCustomWalletSet && customWallet?.length > 30
              ? "bw-w-[87%]"
              : "bw-w-[72%]"
          }   bw-p-[1px] bw-bg-border-primary `}
        >
          <input
            type="text"
            value={customWallet}
            onChange={(e) => {
              setCustomWallet(e.target.value);
              setErrorText("");
              setIsCustomWalletSet(false);
            }}
            placeholder="Enter receiving wallet address"
            className={`bw-w-full  bw-h-[38px] bw-rounded-md bw-text-sm bw-text-text-primary bw-bg-background-container   bw-px-2 placeholder:bw-text-text-secondary `}
          />
        </div>
        {isCustomWalletSet && customWallet?.length > 30 ? (
          <button
            type="button"
            // style={{
            //   boxShadow:
            //     (configuration &&
            //       configuration.customTheme &&
            //       configuration.customTheme?.shadow?.boxShadow) ||
            //     "1px 1px 15px 3px rgba(0, 0, 0, 0.15)",
            // }}
            onClick={(e) => {
              e.stopPropagation();
              setIsCustomWalletSet(false);
              setToDisconnect(true);
              setCustomWallet("");
              // handleShowWallet();
            }}
            className="bw-w-[40px] bw-h-[40px] bw-flex bw-items-center bw-justify-center bw-rounded-md bw-border bw-border-bg-button"
          >
            {/* <img src={disconnecticon} /> */}
            <div className="bw-w-[25px] bw-h-[25px]">
              <DisconnectWallet fill={"var(--tw-text-primary)"} />
            </div>
          </button>
        ) : (
          <div
            style={
              customWallet?.length > 30
                ? {
                    ...configuration?.gradientStyle,
                    boxShadow:
                      (configuration &&
                        configuration.customTheme &&
                        configuration.customTheme?.shadow?.boxShadow) ||
                      "1px 1px 7px rgba(0, 0, 0, 0.15)",
                  }
                : {
                    boxShadow:
                      (configuration &&
                        configuration.customTheme &&
                        configuration.customTheme?.shadow?.boxShadow) ||
                      "1px 1px 7px rgba(0, 0, 0, 0.15)",
                  }
            }
            className={`bw-w-[28%]  bw-flex bw-justify-center bw-items-center bw-rounded-md  ${
              !configuration?.gradientStyle &&
              customWallet?.length > 30 &&
              styles.gradientbutton
            } `}
          >
            <button
              onClick={() => {
                handleSubmit();
              }}
              type="submit"
              className={`bw-cursor-pointer  bw-bg-background-container bw-w-full   bw-rounded-[4px] ${
                customWallet?.length > 30
                  ? " bw-font-bold bw-text-text-primary bw-h-[36px] bw-m-[2px]"
                  : "bw-border bw-font-medium bw-text-text-secondary bw-border-border-primary bw-h-[40px]"
              }  bw-text-lg  `}
            >
              Confirm
            </button>
          </div>
        )}
      </form>

      <p className="bw-text-xs bw-pl-4 bw-mt-1 bw-font-normal bw-text-text-redtext">
        {errorText}
      </p>
    </div>
  );
}

export default CustomWallet;
