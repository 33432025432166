import React from "react";
import { isEmpty } from "lodash";
import useStore from "../../zustand/store";
import { CircularProgress, Skeleton } from "@mui/material";
import styles from "./Exchange.module.css";
import { useStargateSigningClient } from "graz";

function TxnExeButton({
  disableButton,
  handleStep,
  txnProcessType,
  txnTextObj,
}) {
  const persist = useStore((state) => state.persist);
  const configuration = useStore((state) => state.configuration);
  const cosmosChainIds = useStore((state) => state.cosmosChainIds);
  const fromChain = useStore((state) => state.fromChain);
  let style = disableButton ? {} : { ...configuration?.gradientStyle };
  const { data, isLoading } = useStargateSigningClient({
    chainId: [fromChain.chainId],
    multiChain: true,
  });
  let isCosmosClientLoading =
    persist?.fromChain?.networkType == "cosmos" ? isLoading : false;
  console.log(isCosmosClientLoading, data, txnTextObj, "iscosmos");
  return !isEmpty(persist?.stepData) && !isCosmosClientLoading ? (
    <button
      disabled={disableButton || !txnTextObj}
      className={`bw-text-lg bw-flex bw-justify-center bw-items-center bw-gap-x-2 disabled:bw-opacity-60 bw-font-medium   bw-h-[50px] bw-w-full bw-bg-background-container  bw-rounded-sm ${
        disableButton
          ? "bw-bg-border-primary bw-p-[1px]"
          : styles.gradientbutton + " bw-p-[3px]"
      }`}
      onClick={handleStep}
      style={{
        ...style,
        boxShadow:
          (configuration &&
            configuration.customTheme &&
            configuration.customTheme?.shadow?.boxShadow) ||
          "1px 1px 7px rgba(0, 0, 0, 0.15)",
      }}
    >
      <div className="bw-bg-background-container  bw-w-full bw-h-full bw-flex bw-items-center bw-justify-center">
        {disableButton ||
          (!txnTextObj && (
            <div className="bw-w-[30px] bw-flex bw-text-text-secondary bw-justify-center bw-items-center bw-h-[30px]">
              <CircularProgress size={22} thickness={5} color="inherit" />
            </div>
          ))}
        <p
          // style={{ ...configuration.gradientStyle }}
          className={` ${
            disableButton
              ? "bw-text-text-secondary bw-font-normal"
              : "bw-text-text-primary bw-font-bold"
          } bw-text-2xl `}
        >
          {
            txnTextObj?.[
              txnProcessType == "process" ? "processingCta" : "preCta"
            ]
          }
        </p>
      </div>
    </button>
  ) : (
    <Skeleton
      className="bw-bg-background-loaderbar bw-rounded-sm bw-w-full"
      animation="wave"
      height={50}
    />
  );
}

export default TxnExeButton;
