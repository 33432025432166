export default function getSolWalletInstalled(wallet) {
  switch (wallet) {
    case "phantom":
      if (window?.phantom?.solana?.isPhantom) {
        return true;
      } else {
        return false;
      }
    case "backpack":
      if (window?.backpack?.isBackpack) {
        return true;
      } else {
        return false;
      }
    case "solflare":
      if (window?.solflare?.isSolflare) {
        return true;
      } else {
        return false;
      }
    case "magic eden (solana)":
      if (window?.magicEden?.solana?.isMagicEden) {
        return true;
      } else {
        return false;
      }

    default:
      return false;
  }
}
