import React, { useEffect, useState } from "react";
import controllers from "../Actions/Controllers";
import useStore from "../zustand/store";
let rpcUrls = {
  "cosmoshub-4": "https://cosmos-rpc.publicnode.com:443",
  "osmosis-1": "https://osmosis-rpc.publicnode.com",
  "dydx-mainnet-1": "https://dydx-rpc.publicnode.com:443",
  celestia: "https://rpc.cosmos.directory/celestia",
  "kaiyo-1": "https://kujira-rpc.publicnode.com:443",
  "neutron-1": "https://neutron-rpc.publicnode.com:443",
  "stargaze-1": "https://stargaze-rpc.publicnode.com:443",
  "axelar-dojo-1": "https://axelar-rpc.publicnode.com:443",
  "core-1": "https://persistence-rpc.publicnode.com:443",
  "stride-1": "https://stride-rpc.publicnode.com:443",
  "injective-1": "https://injective-rpc.publicnode.com:443",
  "ssc-1": "https://saga-rpc.publicnode.com:443",
  "juno-1": "https://juno-rpc.publicnode.com:443",
  "dymension_1100-1": "https://dymension-rpc.publicnode.com:443",
  sei: "https://sei-rpc.publicnode.com:443",
  "regen-1": "https://regen-rpc.publicnode.com:443",
  chihuahua: "https://chihuahua-rpc.publicnode.com:443",
  "akashnet-2": "https://akash-rpc.publicnode.com:443",
  "comdex-1": "https://comdex-rpc.publicnode.com:443",
  "quicksilver-2": "https://quicksilver-rpc.publicnode.com:443",
  "omniflixhub-1": "https://omniflix-rpc.publicnode.com:443",
  "coreum-mainnet-1": "https://coreum-rpc.publicnode.com:443",
  "migaloo-1": "https://migaloo-rpc.publicnode.com:443",
  "mars-1": "https://mars-rpc.publicnode.com:443",
  "quasar-1": "https://quasar-rpc.publicnode.com:443",
  "lava-mainnet-1": "https://lava-rpc.publicnode.com:443",
  "columbus-5": "https://terra-classic-rpc.publicnode.com:443",
  "mantle-1": "https://asset-mantle-rpc.publicnode.com:443",
  "bitcanna-1": "https://bitcanna-rpc.publicnode.com:443",
  "cheqd-mainnet-1": "https://cheqd-rpc.publicnode.com:443",
  "desmos-mainnet": "https://rpc.mainnet.desmos.network:443",
  "irishub-1": "https://iris-rpc.publicnode.com:443",
  "cataclysm-1": "https://nibiru-rpc.publicnode.com:443",
  // "sentinelhub-2": "https://sentinel-rpc.publicnode.com:443",
  "pirin-1": "https://nolus-rpc.publicnode.com:443",
  "kava_2222-10": "https://kava-rpc.publicnode.com:443",
  "teritori-1": "https://teritori-rpc.publicnode.com:443",
};
let rpcUrls1 = {
  "cosmoshub-4": "https://rpc.cosmos.directory/cosmoshub",
  "osmosis-1": "https://rpc.cosmos.directory/osmosis",
  "dydx-mainnet-1": "https://rpc.cosmos.directory/dydx",
  celestia: "https://rpc.cosmos.directory/celestia",
  "kaiyo-1": "https://rpc.cosmos.directory/kujira",
  "neutron-1": "https://rpc.cosmos.directory/neutron",
  "stargaze-1": "https://rpc.cosmos.directory/stargaze",
  "axelar-dojo-1": "https://rpc-axelar.imperator.co/",
  "noble-1": "https://rpc.cosmos.directory/noble",
  "umee-1": "https://umee-rpc.polkachu.com/",
  "secret-4": "https://secret-rpc-sl.norm.io/",
  "core-1": "https://rpc.cosmos.directory/persistence",
  "sommelier-3": "https://rpc.cosmos.directory/sommelier",
  "stride-1": "https://rpc.cosmos.directory/stride",
  "injective-1": "https://rpc-injective.keplr.app/",
  "ssc-1": "https://rpc.cosmos.directory/saga",
  "juno-1": "https://rpc.cosmos.directory/juno",
  "crescent-1": "https://rpc.cosmos.directory/crescent",
  "dymension_1100-1": "https://rpc.cosmos.directory/dymension",
  "pacific-1": "https://rpc.sei-apis.com/",
  "carbon-1": "https://carbon-rpc.lavenderfive.com/",
  "regen-1": "https://rpc.cosmos.directory/regen",
  "agoric-3": "https://rpc.cosmos.directory/agoric",
  "chihuahua-1": "https://rpc.cosmos.directory/chihuahua",
  "akashnet-2": "https://rpc.cosmos.directory/akash",
  "comdex-1": "https://rpc.cosmos.directory/comdex",
  "archway-1": "https://rpc.cosmos.directory/regen",
  "quicksilver-2": "https://rpc.cosmos.directory/akash",
  "omniflixhub-1": "https://rpc.cosmos.directory/omniflixhub",
  "coreum-mainnet-1": "https://full-node.mainnet-1.coreum.dev:26657/",
  "migaloo-1": "https://migaloo-rpc.publicnode.com:443",
  "mars-1": "https://rpc.cosmos.directory/mars",
  "quasar-1": "https://quasar-rpc.publicnode.com:443",
  "lava-mainnet-1": "https://rpc.cosmos.directory/lava",
  "columbus-5": "https://rpc.cosmos.directory/terra",
  "mantle-1": "https://rpc.assetmantle.one/",
  "gravity-bridge-3": "https://gravitychain.io:26657/",
  "bitcanna-1": "https://rpc.bitcanna.io/",
  "bitsong-2b": "https://bitsong-rpc.panthea.eu/",
  "cheqd-mainnet-1": "https://rpc.cosmos.directory/cheqd",
  "mainnet-3": "https://poseidon.mainnet.decentr.xyz/",
  "desmos-mainnet": "https://rpc.cosmos.directory/desmos",
  "irishub-1": "https://rpc-iris.keplr.app/",
  "ixo-5": "https://rpc.cosmos.directory/impacthub",
  "jackal-1": "https://rpc.jackalprotocol.com/",
  "cataclysm-1": "https://rpc.nibiru.fi",
  "perun-1": "https://rpc.c4e.io/",
  "likecoin-mainnet-2": "https://rpc.cosmos.directory/likecoin",
  "lum-network-1": "https://rpc.cosmos.directory/lumnetwork",
  "sentinelhub-2": "https://rpc.cosmos.directory/sentinel",
  "pirin-1": "https://rpc.cosmos.directory/nolus",
  "kava_2222-10": "https://rpc.data.kava.io/",
  "teritori-1": "https://teritori-rpc.publicnode.com:443",
  "humans_1089-1": "https://rpc.cosmos.directory/humans",
};
function useCosmosChains() {
  const [cosmoschains, setCosmosChains] = useState([]);
  const setCosmosChainIds = useStore((state) => state.setCosmosChainIds);
  async function fetchChains() {
    let res = await controllers.fetchChain();
    let datas = await res.json();
    console.log(datas, "cosmosdatas");
    let cosmosFilterArray = datas.data.filter((item, i) => {
      return item.networkType == "cosmos";
    });
    let cosmosArray = [
      {
        chainId: "cosmoshub-4",
        chainName: "Cosmos Hub",
        rpc: "https://cosmos-rpc.publicnode.com:443",
      },
    ];
    let chainsArray = [];
    cosmosFilterArray.forEach((item) => {
      console.log(item.chainId, rpcUrls[item.chainId], "compare");
      chainsArray.push(item.chainId);
      if (item.chainId !== "cosmoshub-4") {
        cosmosArray.push({
          chainId: item.chainId,
          chainName: item.name,
          rpc: item.chainInfo.rpc,
        });
      }
    });
    setCosmosChainIds(chainsArray);
    setCosmosChains(cosmosArray);
  }

  useEffect(() => {
    fetchChains();
  }, []);

  return cosmoschains;
}

export default useCosmosChains;
