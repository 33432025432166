import images from "../../../images";

import SelectWalletPortfolio from "../SelectWalletPortfolio";
import { useQuery } from "@tanstack/react-query";
import controllers from "../../../Actions/Controllers";
export default function PortfolioDisconnect() {
  const { barchartdisconnect } = images;
  const fetchChains = useQuery({
    queryKey: ["chainsportfolio"],
    queryFn: async function () {
      let res = await controllers.fetchChain();
      return await res.json();
    },

    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  return (
    <div className="bw-w-full bw-flex bw-flex-col bw-items-center">
      <div className="bw-mt-8 bw-relative bw-flex bw-items-center bw-justify-center bw-mb-4">
        <p className="bw-text-text-secondary bw-absolute bw-text-sxm bw-font-normal">
          All Assets <br />
          {fetchChains.isSuccess ? fetchChains.data.data.length : ""} chains
        </p>
        <img width={240} height={240} src={barchartdisconnect} alt="img" />
      </div>
      <p className="bw-text-lg bw-font-medium bw-text-center bw-text-text-secondary">
        Connect multiple wallets and monitor your portfolio across{" "}
        {fetchChains.isSuccess ? fetchChains.data.data.length : ""} chains.
      </p>
      <SelectWalletPortfolio />
    </div>
  );
}
