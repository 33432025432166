import React from "react";
import MenuItem from "@mui/material/MenuItem";
import controllers from "../../../Actions/Controllers";
import { useQuery } from "@tanstack/react-query";
import { Select } from "@mui/material";

import Down from "../../Svg/down";
function MenuContent({ chain }) {
  return (
    <>
      {chain?.image ? (
        <img
          src={chain.image}
          className="bw-min-w-[22px] bw-rounded-[50%] bw-border bw-border-border-primary bw-min-h-[22px]"
          width={22}
          height={22}
          alt="img"
        />
      ) : (
        <></>
      )}
      <p className="">
        {chain.name?.length > 10
          ? chain.name.substring(0, 10) + ".."
          : chain.name}
      </p>
    </>
  );
}
export default function SelectChainDropdown({ selectChain, handleChain }) {
  const fetchChains = useQuery({
    queryKey: ["chainsportfolio"],
    queryFn: async function () {
      let res = await controllers.fetchChain();
      return await res.json();
    },

    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  return (
    <div style={{ position: "relative", zIndex: 10 }}>
      <Select
        value={selectChain}
        displayEmpty
        onChange={handleChain}
        multiple
        MenuProps={{
          disablePortal: true,
          PaperProps: {
            style: {
              maxHeight: 300,
              padding: 0,
              paddingBottom: "0px",
              paddingTop: 0,
              background: "var(--tw-background-container)",
              border: "1px solid var(--tw-border-primary)",
              margin: "3px",
            },
          },
        }}
        IconComponent={() => (
          <div className="bw-w-[20x] bw-relative bw-right-2 ">
            <Down fill={"var(--tw-text-primary)"} />
          </div>
        )}
        renderValue={(selected) => {
          let selectedTemp = [...selected];
          let slicedVal =
            selected.length > 1 ? selectedTemp.slice(1) : selectedTemp;
          if (selected.length === 0) {
            return <em>All Chains</em>;
          }
          let tempArray = [];
          slicedVal.forEach((item, i, arr) => {
            tempArray.push(
              arr.length > 1 ? item.name.substring(0, 3) : item.name
            );
          });
          return tempArray.join(",");
        }}
        className="bw-relative   bw-text-center bw-z-0 bw-flex bw-gap-x-2  bw-items-center bw-justify-center bw-w-[147px] bw-h-[35px]  bw-text-sm bw-font-bold  bw-rounded-2xl bw-border bw-border-border-primary bw-bg-background-container bw-text-text-primary"
      >
        <div className="bw-gap-x-2 bw-py-1 bw-cursor-pointer bw-text-text-primary bw-text-sm bw-font-normal">
          <p
            onClick={(e) => {
              e.stopPropagation();
              handleChain({ name: "All Chains" });
            }}
            className="bw-text-text-primary bw-text-left bw-pl-5  bw-text-sm bw-font-normal"
          >
            All Chains
          </p>
        </div>
        {fetchChains.data?.data
          // ?.filter((item) => item.networkType !== "cosmos")
          .map((chain, i) => (
            <MenuItem
              value={chain}
              key={chain.name}
              className="bw-gap-x-2 bw-text-text-primary bw-text-sm bw-font-normal"
            >
              <MenuContent chain={chain} />
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
