export default function parseAddress(address) {
  let splitAddress = address.split("/");
  if (splitAddress.length > 1) {
    let upperCase = splitAddress[1].toUpperCase();
    splitAddress[1] = upperCase;
    return splitAddress.join("/");
  } else {
    return address;
  }
}
