import {
  GasPrice,
  calculateFee,
  createIbcAminoConverters,
  AminoTypes,
} from "@cosmjs/stargate";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { toUtf8 } from "@cosmjs/encoding";
import {
  SigningCosmWasmClient,
  createWasmAminoConverters,
} from "@cosmjs/cosmwasm-stargate";
import Long from "long";
const IBC_TRANSFER_TYPE = "/ibc.applications.transfer.v1.MsgTransfer";
const WASM_TYPE = "/cosmwasm.wasm.v1.MsgExecuteContract";
function getAminoTypeConverters() {
  return new AminoTypes({
    ...createIbcAminoConverters(),
    ...createWasmAminoConverters(),
  });
}
function getTimeoutTimestamp() {
  const PACKET_LIFETIME_NANOS = 3600 * 1_000_000_000; // 1 Hour

  const currentTimeNanos = Math.floor(Date.now() * 1_000_000);
  const timeoutTimestamp = Long.fromNumber(
    currentTimeNanos + PACKET_LIFETIME_NANOS
  );
  return timeoutTimestamp.toNumber();
}
async function executeRouteCosmos(
  signer,
  signerAddress,
  route,
  useBroadcast = true,
  fromToken
) {
  const cosmosMsg = JSON.parse(route.transactionRequest.data);
  console.log(cosmosMsg, fromToken, signer, "routecos");
  const msgs = [];
  switch (cosmosMsg.msgTypeUrl) {
    case IBC_TRANSFER_TYPE: {
      msgs.push({
        typeUrl: cosmosMsg.msgTypeUrl,
        value: cosmosMsg.msg,
      });

      break;
    }
    case WASM_TYPE: {
      // register execute wasm msg type for signer
      signer.registry.register(WASM_TYPE, MsgExecuteContract);

      const wasmHook = cosmosMsg.msg;
      msgs.push({
        typeUrl: cosmosMsg.msgTypeUrl,
        value: {
          sender: signerAddress,
          contract: wasmHook.wasm.contract,
          msg: toUtf8(JSON.stringify(wasmHook.wasm.msg)),
          funds: [
            {
              denom: fromToken.from.address,
              amount: fromToken.inputAmount,
            },
          ],
        },
      });

      break;
    }
  }
  const aminoTypes = getAminoTypeConverters();
  const firstMsg = msgs[0];
  const formattedMsg = {
    ...firstMsg,
    value: {
      ...firstMsg.value,
      // Memo cannot be undefined, otherwise amino converter throws
      memo: firstMsg.value.memo || "",
      // Timeout wasn't formatted in the right way, so getting it manually
      timeoutTimestamp: getTimeoutTimestamp(),
    },
  };

  const aminoMsg = aminoTypes.toAmino(formattedMsg);
  const fromAminoMsg = aminoTypes.fromAmino(aminoMsg);
  console.log(aminoMsg, fromAminoMsg, signer, signerAddress, "amino");
  // simulate tx to estimate gas cost
  const estimatedGas = await signer.simulate(signerAddress, [fromAminoMsg], "");
  const gasMultiplier = Number(route.transactionRequest.maxFeePerGas) || 1.5;
  console.log(estimatedGas, gasMultiplier, "estgas");
  const fee = calculateFee(
    Math.trunc(estimatedGas * gasMultiplier),
    GasPrice.fromString(route.transactionRequest.gasPrice)
  );
  console.log(signerAddress, fromAminoMsg, fee, "final txn data");
  if (useBroadcast) {
    return signer.signAndBroadcast(signerAddress, [fromAminoMsg], fee, "");
  }
  return signer.sign(signerAddress, [fromAminoMsg], fee, "");
}

export default executeRouteCosmos;
