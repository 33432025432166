import { injected, walletConnect } from "wagmi/connectors";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import {
  mainnet,
  polygon,
  optimism,
  goerli,
  sepolia,
  polygonMumbai,
  bsc,
  zkSync,
  base,
  arbitrum,
  fantom,
  avalanche,
  polygonZkEvm,
  fuse,
  boba,
  okc,
  moonriver,
  aurora,
  linea,
  gnosis,
  moonbeam,
  cronos,
  evmos,
  telos,
  harmonyOne,
} from "@wagmi/core/chains";
import { http, createConfig, WagmiProvider, configureChains } from "wagmi";
const chains = [
  polygon,
  optimism,
  mainnet,
  goerli,
  sepolia,
  polygonMumbai,
  bsc,
  zkSync,
  base,
  arbitrum,
  fantom,
  avalanche,
  polygonZkEvm,
  fuse,
  boba,
  okc,
  moonriver,
  aurora,
  linea,
  gnosis,
  moonbeam,
  cronos,
  evmos,
  telos,
  harmonyOne,
];

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

const metadata = {
  name: "Your App",
  description: "Your App Description",
  url: window.location.origin,
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId: "a3cc5b84df95db911e2f9f9655114425",
  enableWalletConnect: true,
  enableInjected: false, // Disable Injected/Metamask
  enableEIP6963: false, // Disable EIP-6963 wallets
  enableCoinbase: false,
});

const config = createConfig({
  multiInjectedProviderDiscovery: true,

  chains,
  transports: {
    [polygon.id]: http(
      "https://polygon-mainnet.g.alchemy.com/v2/UPTSl0S8hg5frz84jM4q1xc1pJzjHL86"
    ),
    [optimism.id]: http(),
    [mainnet.id]: http(),
    [goerli.id]: http(),
    [sepolia.id]: http(),
    [polygonMumbai.id]: http(),
    [bsc.id]: http(),
    [zkSync.id]: http(),
    [base.id]: http(),
    [arbitrum.id]: http(),
    [fantom.id]: http(),
    [avalanche.id]: http(),
    [polygonZkEvm.id]: http(),
    [fuse.id]: http(),
    [boba.id]: http(),
    [okc.id]: http(),
    [moonriver.id]: http(),
    [aurora.id]: http(),
    [linea.id]: http(),
    [gnosis.id]: http(),
    [moonbeam.id]: http(),
    [cronos.id]: http(),
    [evmos.id]: http(),
    [telos.id]: http(),
    [harmonyOne.id]: http(),
  },
  ...wagmiConfig,
  connectors: [
    injected(),
    walletConnect({
      projectId: "a3cc5b84df95db911e2f9f9655114425",
      showQrModal: true,
      qrModalOptions: {
        themeMode: "light",
      },
    }),
  ],
});
createWeb3Modal({
  wagmiConfig: config,
  projectId: "a3cc5b84df95db911e2f9f9655114425",
  defaultChain: mainnet,
  themeMode: "light",
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
});
export default config;
