import { useEffect, useState } from "react";
import SelectWallet from "../../SelectWallet";
import styles from "./PortfolioDisconnect.module.css";
import useStore from "../../../zustand/store";
import AddWallet from "../../Svg/addWallet";
import RoundedButton from "../../Button/RoundedButton";
import { useAccount } from "wagmi";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
export default function SelectWalletPortfolio({ wallet }) {
  const [showWallet, setShowWallet] = useState(false);
  const configuration = useStore((state) => state.configuration);
  const { isConnected } = useAccount();
  const { primaryWallet } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const {
    address: tronAddress,
    connected,
    disconnect: tronDisconnect,
  } = useWallet();
  function handleShowWallet() {
    setShowWallet((prev) => !prev);
  }
  return (
    <>
      {wallet === "connected" ? (
        isConnected && isFullyConnected && connected ? (
          <></>
        ) : (
          <div
            onClick={() => {
              handleShowWallet();
            }}
          >
            <RoundedButton
              classnames={"bw-border-[1.5px] bw-border-border-primary"}
            >
              <AddWallet fill={"var(--tw-text-primary)"} />
            </RoundedButton>
          </div>
        )
      ) : (
        <button
          onClick={() => {
            handleShowWallet();
          }}
          style={{
            ...configuration?.gradientStyle,
            boxShadow:
              (configuration &&
                configuration.customTheme &&
                configuration.customTheme?.shadow?.boxShadow) ||
              "1px 1px 7px rgba(0, 0, 0, 0.15)",
          }}
          className={`${
            !configuration?.gradientStyle && styles.gradientbutton
          } bw-w-full bw-h-[50px] bw-text-2xl bw-p-[3px] bw-mt-12 bw-border-0 bw-relative bw-z-0  disabled:bw-opacity-60  bw-rounded-sm  bw-font-bold bw-text-text-primary`}
        >
          <div className="bw-w-full bw-flex bw-justify-center bw-items-center bw-rounded-sm bw-h-full bw-bg-background-container">
            <p className="bw-relative bw-z-20 ">Connect Wallet</p>
          </div>
        </button>
      )}
      {showWallet && (
        <div className="bw-absolute bw-z-20 bw-p-3 bw-rounded-[15px]  bw-right-0  bw-bg-background-container bw-h-full bw-top-0 bw-w-full">
          <SelectWallet handleShowWallet={handleShowWallet} portfolio={true} />
        </div>
      )}
    </>
  );
}
