import React from "react";

function DisconnectWallet({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11.7148"
        y="4.08594"
        width="1.60573"
        height="9.09916"
        rx="0.802867"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3146 5.80529C16.5295 5.92348 16.738 6.05125 16.9396 6.18801C18.1562 7.01313 19.1223 8.16579 19.7212 9.51974C20.4268 11.1151 20.5828 12.8995 20.1647 14.5931C19.7465 16.2867 18.7778 17.7934 17.4107 18.8769C16.0435 19.9605 14.3554 20.5594 12.611 20.5797C10.8667 20.6 9.16506 20.0405 7.77309 18.9891C6.38111 17.9377 5.37764 16.4539 4.92016 14.7705C4.46268 13.0871 4.57709 11.2995 5.24543 9.68821C5.81266 8.32069 6.75162 7.14586 7.94869 6.29265C8.16209 6.14055 8.3837 5.99866 8.61287 5.86773L8.61451 5.8706C8.71383 5.81424 8.82868 5.78206 8.95105 5.78206C9.3282 5.78206 9.63394 6.0878 9.63394 6.46495C9.63394 6.74011 9.47121 6.97726 9.23674 7.0854C8.01077 7.8015 7.05203 8.89924 6.50763 10.2117C5.95526 11.5435 5.8607 13.0209 6.2388 14.4122C6.6169 15.8034 7.44624 17.0298 8.59668 17.8987C9.74711 18.7677 11.1535 19.2301 12.5951 19.2133C14.0368 19.1965 15.432 18.7015 16.5619 17.806C17.6918 16.9105 18.4924 15.6652 18.838 14.2655C19.1836 12.8658 19.0547 11.391 18.4715 10.0725C17.8885 8.75448 16.8845 7.66724 15.617 6.98132L15.6157 6.9806L15.6163 6.97936C15.4179 6.8599 15.2851 6.64237 15.2851 6.39384C15.2851 6.01668 15.5908 5.71094 15.968 5.71094C16.0747 5.71094 16.1756 5.7354 16.2656 5.77901L16.2658 5.77867C16.2688 5.78032 16.2719 5.78197 16.2749 5.78363C16.2884 5.79042 16.3016 5.79765 16.3146 5.80529Z"
        fill={fill}
      />
      <g filter="url(#filter0_f_5308_21436)">
        <rect
          x="11.7148"
          y="4.08594"
          width="1.60573"
          height="9.09916"
          rx="0.802867"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.3146 5.80529C16.5295 5.92348 16.738 6.05125 16.9396 6.18801C18.1562 7.01313 19.1223 8.16579 19.7212 9.51974C20.4268 11.1151 20.5828 12.8995 20.1647 14.5931C19.7465 16.2867 18.7778 17.7934 17.4107 18.8769C16.0435 19.9605 14.3554 20.5594 12.611 20.5797C10.8667 20.6 9.16506 20.0405 7.77309 18.9891C6.38111 17.9377 5.37764 16.4539 4.92016 14.7705C4.46268 13.0871 4.57709 11.2995 5.24543 9.68821C5.81266 8.32069 6.75162 7.14586 7.94869 6.29265C8.16209 6.14055 8.3837 5.99866 8.61287 5.86773L8.61451 5.8706C8.71383 5.81424 8.82868 5.78206 8.95105 5.78206C9.3282 5.78206 9.63394 6.0878 9.63394 6.46495C9.63394 6.74011 9.47121 6.97726 9.23674 7.0854C8.01077 7.8015 7.05203 8.89924 6.50763 10.2117C5.95526 11.5435 5.8607 13.0209 6.2388 14.4122C6.6169 15.8034 7.44624 17.0298 8.59668 17.8987C9.74711 18.7677 11.1535 19.2301 12.5951 19.2133C14.0368 19.1965 15.432 18.7015 16.5619 17.806C17.6918 16.9105 18.4924 15.6652 18.838 14.2655C19.1836 12.8658 19.0547 11.391 18.4715 10.0725C17.8885 8.75448 16.8845 7.66724 15.617 6.98132L15.6157 6.9806L15.6163 6.97936C15.4179 6.8599 15.2851 6.64237 15.2851 6.39384C15.2851 6.01668 15.5908 5.71094 15.968 5.71094C16.0747 5.71094 16.1756 5.7354 16.2656 5.77901L16.2658 5.77867C16.2688 5.78032 16.2719 5.78197 16.2749 5.78363C16.2884 5.79042 16.3016 5.79765 16.3146 5.80529Z"
          fill={fill}
        />
      </g>
      <defs>
        <filter
          id="filter0_f_5308_21436"
          x="0.644531"
          y="0.0859375"
          width="23.75"
          height="24.4941"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_5308_21436"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default DisconnectWallet;
